<template>
  <div class="user-actions">
    <!--
    <div class="login me-3">
      <img class="icon" src="../assets/icons/user-login.svg" alt="">
      <p class="m-0 text-white">{{ $t('login') }}</p>
    </div>
    -->
    <LanguageSelector />
  </div>
</template>

<script>
import LanguageSelector from './LanguageSelector.vue'

export default {
  components: {
    LanguageSelector,
  }
}
</script>

<style scoped>
.icon {
  width: 25px;
  height: 25px;
}

.user-actions {
  display: flex;
  align-items: center;
}

.login {
  display: flex;
  align-items: center;
  gap: 8px;
}

.login p {
  font-size: 14px;
}
</style>
