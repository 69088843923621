<template>
  <div
    id="carouselExampleControls"
    class="carousel slide"
    data-bs-ride="carousel"
  >
    <div class="carousel-inner">
      <div
        v-for="(banner, index) of carousel"
        :key="index"
        class="carousel-item"
        :class="{ active: index === 0 }"
      >
        <img :src="breakpoint !== 'xs' ? banner.url : banner.url_mobile" class="ratio ratio-1x1 banner d-block w-100 h-100 mh-100" :alt="`Banner ${banner.page}`" />
      </div>
    </div>
    <button
      v-if="carousel.length > 1"
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouselExampleControls"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      v-if="carousel.length > 1"
      class="carousel-control-next"
      type="button"
      data-bs-target="#carouselExampleControls"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
import { useAppStore } from "@/stores/app";
import { computed } from "vue";

export default {
  props: {
    breakpoint: {
      type: String,
      required: true,
    },
  },
  setup() {
    const app = useAppStore();

    const carousel = computed(() => {
      return app.banners.filter((banner) => banner.page === 'home').sort((a, b) => a.position - b.position);
    });

    return {
      carousel,
    };
  },
}
</script>

<style scoped>
.carousel-item .banner {
  height: 70vh;
  max-height: 70vh;
  object-fit: fill;
}

@media screen and (min-width: 901px) and (max-width: 1199px) {
  .carousel-item .banner {
    height: 60vh;
    max-height: 60vh;
  }
}

@media screen and (max-width: 768px) {
  .carousel-item .banner {
    height: 75vh;
    max-height: 75vh;
  }
}

@media screen and (min-width: 426px) and (max-width: 900px) {
  .carousel-item .banner {
    height: 50vh;
    max-height: 50vh;
  }
}

@media screen and (min-width: 300px) and (max-width: 425px) {
  .carousel-item .banner {
    height: 30vh;
    max-height: 30vh;
  }
}

</style>
