<template>
  <div v-if="routeName === 'home'">
    <Carousel :breakpoint="breakpoint" />
  </div>
  <div
    v-if="routeName !== 'home' && bannerImage" class="banner"
    :style="{ backgroundImage: `url(${breakpoint !== 'xs' ? bannerImage?.url : bannerImage?.url_mobile})`, aspectRatio: '16/9' }"
  >
    <div class="d-flex justify-content-start align-items-center h-100 px-3">
      <div data-aos="fade-right" class="text-md-center px-md-5">
        <!--
        <div class="d-block d-sm-block d-md-block d-lg-none" v-if="routeName === 'home'">
          <img src="@/assets/icons/animal-welfare-goat-big.svg" alt="">
        </div>
      -->
        <div class="bg-title">
          <h1 id="banner-text" v-html="$t(bannerText[routeName])"
            class="font-montserrat-sb text-white font-weight-bold m-0"></h1>
        </div>

        <!--
        <div v-if="routeName === 'home'" class="green">
          <h1 class="m-0">199$ {{ $t('price-banner') }}</h1>
        </div>

        <ButtonHover
          class="mt-4 mb-5 mx-auto d-block d-sm-block d-md-block d-lg-none"
          backgroundColor="#9a7a2a"
          :btn-text="$t('button-get-certified')"
        />
        -->
      </div>
      <!--
      <div class="d-none d-sm-none d-md-none d-lg-block" data-aos="zoom-out-left" v-if="routeName === 'home'">
        <img src="@/assets/icons/animal-welfare-goat-big.svg" alt="">
      </div>
      -->
    </div>
  </div>
</template>

<script>

import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { computed } from "vue";
import Carousel from "./Carousel.vue";
import { useAppStore } from "@/stores/app";
import { useBreakpoints } from "@/helpers/useBreakpoints";
//import ButtonHover from './ButtonHover.vue'

export default {
  components: {
    //Header,
    Carousel
    //ButtonHover
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const { type } = useBreakpoints();

    const routeName = computed(() => route.name);

    const app = useAppStore();

    const bannerImage = computed(() => {
      return app.banners.find((banner) => banner.page === routeName.value);
    });

    const bannerText = {
      home: "banner-home-text",
      about: "banner-text-about",
      contact: "banner-text-contact",
      "find-us": "banner-text-find-us",
      training: "banner-text-training",
      services: "banner-text-services",
      pricing: "banner-text-pricing",
      products: "banner-text-products",
      certification: "banner-text-certification",
    };

    return {
      t,
      routeName,
      bannerText,
      //banners,
      bannerImage,
      breakpoint: type
    };
  },
};
</script>

<style scoped>
.banner {
  width: 100%;
  height: 70vh;
  max-height: 70vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  aspect-ratio: 16/9;
}

.banner-text {
  color: white;
  /*background: rgb(29, 59, 31, 0.4);*/
  height: 100%;
}

.banner-text img {
  width: 15em;
}

.bg-title {
  background: rgb(29, 59, 31, 0.7);
  padding: 5px;
  border-radius: 3px;
}

.green {
  background: var(--secondary-color);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .banner {
    height: 75vh;
    max-height: 75vh;
  }

  .banner-text img {
    width: 7em;
  }
}

@media screen and (min-width: 426px) and (max-width: 900px) {
  .banner {
    height: 50vh;
    max-height: 50vh;
  }
}

@media screen and (min-width: 300px) and (max-width: 425px) {
  .banner {
    height: 30vh;
    max-height: 30vh;
    aspect-ratio: 4/3;
    background-size: 100% 100%;
  }
}
</style>
