<template>
  <div class="sponsors">
    <p class="footer-title-section font-montserrat-sb m-0">{{ $t('footer.sponsors')}}</p>

    <div class="icons">
      <img width="45" height="45" loading="lazy" v-for="(item, index) of sponsors" :key="index" :src="item.url" :alt="item.logo">
    </div>

    <p class="font-noto-b text-center">{{ $t('footer.becoming-sponsors')}}</p>
    <div class="d-flex justify-content-center">
      <ButtonHover backgroundColor="#665729" :btn-text="$t('click-here')" @click="openForm()"></ButtonHover>
    </div>
    <p class="font-montserrat-m">{{ $t('footer.sponsors-p')}}</p>
  </div>
</template>

<script>
import ButtonHover from "@/components/ButtonHover.vue";
import { useRoute, useRouter } from 'vue-router'
import { computed } from 'vue'
import { useAppStore } from "@/stores/app";

export default {
  components: {
    ButtonHover
  },
  setup() {
    const router = useRouter()
    const route = useRoute();
    const app = useAppStore();

    const routeName = computed(() => route.name)
    const sponsors = computed(() => app.sponsors);

    const openForm = () => {
      if (routeName.value !== 'contact') {
        router.push({
          name: 'contact',
          hash: '#form-contact'
        })
      } else {
        const el = document.querySelector('#form-contact');
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }

    return {
      sponsors,
      openForm
    }
  }
}
</script>

<style scoped>
.sponsors {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px
}

.sponsors .icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
}

.sponsors .icons img {
  width: 3em
}

.sponsors p {
  font-size: 15px;
}
</style>
