import { createApp } from 'vue'
import '@fontsource-variable/montserrat';
import '@fontsource-variable/montserrat/wght-italic.css';
import '@fontsource-variable/noto-sans-jp';
import './style.css'
import './animations.css'
import App from './App.vue'
import router from './router/router'
import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle"
import { createI18n } from 'vue-i18n'
import messages from './utils/i18n/translations'
import { createPinia } from 'pinia'
import 'aos/dist/aos.css'
import languagesByHost from './helpers/languagesByHost'
import interceptors from "@/application/interceptors"

interceptors()

import { configure } from 'vee-validate';
// Default values
configure({
  validateOnBlur: false, // controls if `blur` events should trigger validation with `handleChange` handler
  validateOnChange: false, // controls if `change` events should trigger validation with `handleChange` handler
  validateOnInput: false, // controls if `input` events should trigger validation with `handleChange` handler
  validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
  validateOnMount: false, // controls if the component should validate when it is mounted
});

const language = languagesByHost[window.location.hostname] || 'en'

const i18n = createI18n({
  locale: language,
  allowComposition: true,
  messages,
})

createApp(App)
  .use(router)
  .use(i18n)
  .use(createPinia())
  .mount('#app')
